<template>
    <div>    
        <b-form>
            <b-row >
                <b-col md="1" xl="1" class="mb-1"> 
                    <b-form-group label="Especie" label-for="basicInput">                        
                        <b-form-select @change="productTypeSelected" :options="productTypes"
                        value-field="productType" text-field="productType" placeholder="Seleccione"/>
                    </b-form-group>                    
                </b-col>
                <b-col md="1" xl="1" class="mb-1">
                    <b-form-group label="Categoría" label-for="basicInput">                        
                        <b-form-select @change="categorySelected" v-model="category" :options="categoryList" value-field="category" text-field="category" placeholder="Seleccione"/>
                    </b-form-group>
                </b-col>
                <b-col md="1" xl="1" class="mb-1"> 
                    <b-form-group label="Departamento" label-for="basicInput">                        
                        <b-form-select @change="onDepartamentoSelect" :options="departamentosList" 
                        value-field="departamento" text-field="departamento" placeholder="Seleccione"/>
                    </b-form-group>                   
                </b-col>
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Precio mínimo Kg" label-for="basicInput">                        
                        <b-form-input v-model = "priceMinimum"></b-form-input>
                    </b-form-group>                    
                </b-col> 
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Precio máximo Kg" label-for="basicInput">                        
                        <b-form-input v-model = "priceMaximum"></b-form-input>
                    </b-form-group>                    
                </b-col>
                <b-col md="1" xl="1" class="mt-2">                          
                    <b-button  variant="primary" @click="getAllCatlleOfferings" >
                        <feather-icon icon="SearchIcon" class="mr-50"/>Filtrar
                    </b-button>
                </b-col>
                <b-col md="2" xl="2" class="mt-2 text-right" offset-lg="2">
                    <b-button  variant="warning" @click="addNewCattleOffering" block>
                        <feather-icon icon="PlusIcon" class="mr-50"/>Adicionar oferta
                    </b-button>                        
                </b-col>                                      
            </b-row>
        </b-form>      

        <b-card-code title="Ofertas de Ganado" no-body>

            <b-table                 
                ref="selectableTable"
                :fields="fields" 
                :items="cattleOfferings"                             
                responsive                
                selectable
                class="mb-0"
                :select-mode="selectMode"
                @row-selected="onRowSelected"                
                >
                <template #cell(cattleOfferCode)="data">
                    <p style="margin:0px">{{(data.item.cattleOfferCode)}}</p> 
                    <b-badge v-if="data.item.isVisible == true " pill variant="success">Visible</b-badge>
                    <b-badge v-if="data.item.isVisible == false" pill variant="danger">No Visible</b-badge>
                </template>
                
                <template #cell(priceKg)="data">
                    $ {{parseFloat(data.item.priceKg).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                </template>

                <template #cell(offerStatus)="data">
                    <b-badge v-if="data.item.offerStatus == 'En Venta'" pill variant="success">En Venta</b-badge>
                    <b-badge v-if="data.item.offerStatus == 'Vendido'" pill variant="danger">Vendido</b-badge>
                </template>

                <template #cell(locationCity)="data">
                    <p style="margin:0px">{{(data.item.locationCity)}} - {{(data.item.locationState)}}</p>
                </template>

                <template #cell(userEmail)="data">
                    <b-badge v-if="data.item.userEmail" pill variant="warning" >{{ data.item.userEmail }}</b-badge>
                    <b-badge v-else pill variant="success" >Sosty</b-badge>
                </template>
            </b-table> 
            
        </b-card-code>
        <b-row v-if="this.cattleOfferings.length <= 0" class="justify-content-center mt-3" style="text-align:center">
            <b-col style="justify-content:center" xl="4" md="6" lg="4"  >
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span><strong>No Hay Información para mostrar</strong></span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>


        <b-modal size="lg" ref="cattleOfferingModal" centered hide-footer title="Digita la siguiente información">            
            <b-card-text>                 
                <b-form>

                    <b-row>
                        <b-col md="4" xl="4" class="mb-0">
                            <label>Código</label>
                            <b-form-input v-model.number="cattleOfferCode" class="mb-1" disabled/>
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Especie" label-for="basicInput">                        
                                <b-form-select @change="productTypeSelected" v-model="productType" :options="productTypes" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <label>Cantidad de Animales</label>
                            <b-form-input v-model.number="amountOfAnimals" placeholder="Digite"/>
                        </b-col> 
                    </b-row>

                    <b-row>
                        <b-col md="4" xl="4" class="mb-0">
                            <label>Peso Promedio Animal en kg</label>                            
                            <cleave id="number2" v-model.number="averageWeightKg" class="form-control" :options="options.number" placeholder="Digite"/>
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <label>Precio Kilogramo</label>                            
                            <cleave id="number2" v-model.number="priceKg" class="form-control" :options="options.number" placeholder="Digite"/>
                        </b-col> 

                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Estado" label-for="basicInput">                        
                                <b-form-select @change="statusTypeSelected" v-model="offerStatus" :options="offerStatusTypes" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Categoría" label-for="basicInput">                        
                                <b-form-select @change="categorySelected" v-model="category" :options="categoryList" value-field="category" text-field="category" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <label>Edad</label>
                            <b-form-input v-model="age" class="mb-1" />
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Raza" label-for="basicInput">                        
                                <b-form-select @change="breedSelected" v-model="breed" :options="breedList" value-field="breed" text-field="breed" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12" xl="12" class="mb-1 mt-1">
                            <b-form-checkbox v-model="isVisible" checked="false" name="check-button" switch inline> 
                                Es Visible
                            </b-form-checkbox>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12" xl="12" class="mb-1">
                            <label>Descripción</label>
                            <b-form-textarea v-model="offerDescription" id="textarea-default" placeholder="Digite Descripción" rows="2"/>
                        </b-col> 
                    </b-row>

                    <b-row>                    
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Departamento" label-for="basicInput">                        
                                <b-form-select @change="onDepartamentoSelect" v-model="locationState" :options="departamentosList" value-field="departamento" text-field="departamento" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>   
                  
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Municipio/Ciudad" label-for="basicInput">                        
                                <b-form-select v-model="locationCity" :options="municipiosList" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12" xl="12" class="mb-0">
                            <label>Detalles Ubicación</label>
                            <b-form-textarea v-model="locationArrivalIndications" id="textarea-default" placeholder="Digite Detalles" rows="2"/>
                        </b-col> 
                    </b-row>

                    <b-row class="mt-2">
                        <b-col>
                            <h6>
                                Carga de Imágenes
                            </h6>
                        </b-col>                        
                    </b-row>

                    <b-row >
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Imagen 1" label-for="basicInput">
                                <b-form-file placeholder="Seleccione" v-model="projectImageFile1"/>
                                <b-card-text @click="openDocument(projectImageUrl1)" accept="image/*"  v-if="projectImageUrl1" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>

                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Imagen 2" label-for="basicInput">
                                <b-form-file placeholder="Seleccione" v-model="projectImageFile2" />
                                <b-card-text @click="openDocument(projectImageUrl2)" accept="image/*"  v-if="projectImageUrl2" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2">
                        <b-col>
                            <h6>
                                Carga de Videos
                            </h6>
                        </b-col>                        
                    </b-row>
                    <b-row>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Video 1" label-for="basicInput">
                                <b-form-file placeholder="Seleccione" v-model="videoFile1" />
                                <b-card-text @click="openVideo(projectVideoUrl1)" v-if="projectVideoUrl1" class="cursor-pointer sosty-font-smaller" style="color:#00BD56">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" xl="6" class="mb-0">
                            <b-form-group label="Video 2" label-for="basicInput">
                                <b-form-file placeholder="Seleccione" v-model="videoFile2" />
                                <b-card-text @click="openVideo(projectVideoUrl2)" v-if="projectVideoUrl2" class="cursor-pointer sosty-font-smaller" style="color:#00BD56">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row  class="justify-content-end">
                        <b-col v-if="!processing" class="mt-1 mb-1" md="12" lg="12">
                            <b-button variant="primary" @click="saveCattleOffering" block>                                                            
                                Guardar
                            </b-button>
                        </b-col>
                        <b-col v-if="processing" class="mt-1 mb-1" md="12" lg="12">
                            <b-button variant="primary" disabled block>
                                <b-spinner small label="Cargando..." />
                            </b-button>
                        </b-col>
                    </b-row>                    
                </b-form>                
            </b-card-text>        
        </b-modal>

        
        <b-modal centered ref="buttons-cattle-offering-modal" hide-footer title="¿Que deseas hacer?">
            <b-button class="mt-1" variant="info" block @click="viewCattleOffering">
                <feather-icon icon="EyeIcon" class="mr-50"/>
                <span class="align-middle">Ver Lote</span>
            </b-button> 

            <b-button class="mt-2" variant="primary" block @click="editCattleOffering">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Editar Oferta</span>
            </b-button>

            <b-button class="mt-2" variant="warning" block @click="changeStatusCattleOffering">
                <feather-icon icon="Trash2Icon" class="mr-50"/>
                <span class="align-middle">Cambiar Estado de la Oferta</span>
            </b-button>

            <b-button class="mt-2" variant="danger" block @click="deleteCattleOffering">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Eliminar Oferta</span>
            </b-button> 
        </b-modal>

    </div>  
  </template>

  <script>
  
  import { BLink, BButton, BImg, BSpinner,BRow, BCol, BFormInput, BFormTextarea, BFormGroup,
         BTable, BAlert, BBadge, BFormFile, BForm, BFormSelect, BFormCheckbox, BCardText} from 'bootstrap-vue'
  import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';   
  import Cleave from 'vue-cleave-component';
  import departamentos from '../../assets/departamentos-municipios.json'
  import categories from '../../assets/category-types.json'
  import breeds from '../../assets/breeds-types.json'
  import axios from '@axios'
  
  export default {
    components: { 
        BAlert,       
        BLink,
        BButton,
        BCardText,
        BImg,
        BSpinner,
        BBadge,
        BRow,
        BCol,
        BForm,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BFormFile,
        BTable,
        BCardCode,
        BFormSelect,
        BFormCheckbox,
        Cleave,
    },
    data() {
      return {
        selectMode: 'single',
        fields: [
            { key: 'cattleOfferCode', label: 'Código' }, 
            { key: 'offerStatus', label: 'Estado'},
            { key: 'productType', label: 'Especie' },    
            { key: 'category', label: 'Categoría' },
            { key: 'breed', label: 'Raza' }, 
            { key: 'amountOfAnimals', label: 'Cantidad Animales' },
            { key: 'averageWeightKg', label: 'Peso Promedio' },
            { key: 'priceKg', label: 'Precio Kg' },
            { key: 'locationCity', label: 'Ubicación' },
            { key: 'offerDescription', label: 'Descripción' },
            { key: 'userEmail', label: 'Usuario' },
        ],
        productTypes: [
            '',
            'Bufalo',
            'Vacuno'
        ],
        cattleOfferings:[{}],               
        offerStatusTypes: [
            'En Venta',
            'Vendido'
        ],
        categoryList: categories,
        breedList: breeds,
        options: {
            date: {
            date: true,
            delimiter: '-',
            datePattern: ['Y', 'm', 'd'],
            },
            number: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            },
        },
        offerStatus: null,
        isVisible: null,
        offerDescription: null,
        locationState: '',
        locationCity: null,
        locationArrivalIndications: null,
        projectVideoUrl1: null,
        projectVideoUrl2: null,
        projectImageUrl1: null,
        projectImageUrl2: null,
        departamentosList: departamentos,
        departamentoSelected:'',
        municipiosList: [],
        cattleOfferCode: null,
        amountOfAnimals: null,
        averageWeightKg: null,
        priceKg: null,
        processing: null,
        productType:'',  
        cattleOfferingID: null,
        selectedCattleOffering: {},
        projectImageFile1: null,
        projectImageFile2: null,
        category: '',
        age: null,
        priceMinimum: '',
        priceMaximum: '',
        breed: '3/4 Gyr',   
        videoFile1: null,
        videoFile2: null,    

      }
    },
    created() {
      this.getAllCatlleOfferings()
    },  

    watch:{
        projectImageFile1: function(val) { this.projectImageFile1 = val; this.uploadFile(val, 'projectImageFile1'); },
        projectImageFile2: function(val) { this.projectImageFile2 = val; this.uploadFile(val, 'projectImageFile2'); },
        videoFile1: function(val) {this.videoFile1 = val; this.uploadVideo(val, 'videoFile1')},
        videoFile2: function(val) {this.videoFile2 = val; this.uploadVideo(val, 'videoFile2')},
    },
 
    methods:{
        getAllCatlleOfferings(){
            axios.get('/CattleOffering/GetAllCatlleOfferings',{params: {productType: this.productType, category: this.category, 
                state: this.locationState, priceMinimum: this.priceMinimum, priceMaximum: this.priceMaximum}})
            .then(response => {                
                this.cattleOfferings = response.data
                this.cattleOfferings.forEach(function(item, index){
                    if(item.priceKg == null){
                        item.priceKg = 0
                    }
                });
            })
        },
        onRowSelected(items){            
            this.selectedCattleOffering = items[0];
            if(this.selectedCattleOffering) this.$refs['buttons-cattle-offering-modal'].show()
            
        },
        addNewCattleOffering(){
            this.processing = false;      
            this.cattleOfferingID = undefined;
            this.cattleOfferCode = null,
            this.productType = null,
            this.amountOfAnimals = null,
            this.averageWeightKg = null,
            this.priceKg = null,
            this.offerStatus = null,
            this.isVisible = false,
            this.offerDescription = null,
            this.locationState = null,
            this.locationCity = null,
            this.locationArrivalIndications = null,
            this.projectVideoUrl1 = null,
            this.projectVideoUrl2 = null,
            this.projectImageUrl1 = null,  
            this.projectImageUrl2 = null,  
            this.category = null,
            this.age = null,
            this.breed = null,
            this.$refs['cattleOfferingModal'].show()
            
        },
        productTypeSelected(event){
            this.productType = event;

        },
        statusTypeSelected(event){
            this.offerStatus = event;
        },
        categorySelected(event){
            this.category = event;
        },
        breedSelected(event){
            this.breed = event;
        },

        onDepartamentoSelect(event){
            this.locationState = event;        
            var departamento = this.departamentosList.find(x => x.departamento === event);
            this.municipiosList = departamento.ciudades;
        },

        changeStatusCattleOffering(){
            axios.get('/CattleOffering/ChangeStatusCattleOffering', { params: { cattleOfferingID: this.selectedCattleOffering.cattleOfferingID, offerStatus: this.selectedCattleOffering.offerStatus}})
            .then(response => {
                this.$refs['buttons-cattle-offering-modal'].hide();
                window.location.reload()
            });
        },
        
        deleteCattleOffering(){            
            axios.get('/CattleOffering/DeleteCattleOffering', { params: { cattleOfferingID: this.selectedCattleOffering.cattleOfferingID }})
            .then(response => {
                this.$refs['buttons-cattle-offering-modal'].hide();
                window.location.reload()
            })
            .catch(error => {
                this.processing = false;
                if(error.response){
                    this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                    this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
        },

        saveCattleOffering() {
            this.processing = true;            
            var payload = {
                cattleOfferingID: this.cattleOfferingID,
                cattleOfferCode: this.cattleOfferCode,
                productType: this.productType,
                amountOfAnimals: this.amountOfAnimals,
                averageWeightKg: this.averageWeightKg,
                priceKg: this.priceKg,
                offerStatus: this.offerStatus,
                isVisible: this.isVisible,
                offerDescription: this.offerDescription,
                locationState: this.locationState,
                locationCity: this.locationCity,
                locationArrivalIndications: this.locationArrivalIndications,
                projectVideoUrl1: this.projectVideoUrl1,   
                projectVideoUrl2: this.projectVideoUrl2,      
                projectImageUrl1: this.projectImageUrl1,  
                projectImageUrl2: this.projectImageUrl2, 
                category: this.category,
                age: this.age,
                breed: this.breed,    
                userID: this.userID
            }           
                    
            axios.post('/CattleOffering/SaveCattleOffering', payload)
            .then(response => {
                this.processing = false;
                this.$refs['buttons-cattle-offering-modal'].hide()
                this.$refs['cattleOfferingModal'].hide();  
                this.$bvToast.toast("La información ha sido guardada", { title: 'Ok', variant: 'success', solid: true})  
                
                this.getAllCatlleOfferings();

            })
            .catch(error => {
            this.processing = false;
                if(error.response){
                this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
        },
        editCattleOffering(){            
            this.cattleOfferingID = this.selectedCattleOffering.cattleOfferingID;
            this.cattleOfferCode = this.selectedCattleOffering.cattleOfferCode;
            this.productType = this.selectedCattleOffering.productType;
            this.amountOfAnimals = this.selectedCattleOffering.amountOfAnimals;
            this.averageWeightKg = this.selectedCattleOffering.averageWeightKg;
            this.priceKg = this.selectedCattleOffering.priceKg;
            this.offerStatus = this.selectedCattleOffering.offerStatus;
            this.isVisible = this.selectedCattleOffering.isVisible;
            this.offerDescription = this.selectedCattleOffering.offerDescription;
            this.locationState = this.selectedCattleOffering.locationState;
            this.locationCity = this.selectedCattleOffering.locationCity;
            this.locationArrivalIndications = this.selectedCattleOffering.locationArrivalIndications;
            this.projectImageUrl2 = this.selectedCattleOffering.projectImageUrl2;
            this.projectImageUrl1 = this.selectedCattleOffering.projectImageUrl1;
            this.projectVideoUrl1 = this.selectedCattleOffering.projectVideoUrl1;
            this.projectVideoUrl2 = this.selectedCattleOffering.projectVideoUrl2;
            this.userID = this.selectedCattleOffering.userID;
            if(this.selectedCattleOffering.category != null){
                this.category = this.selectedCattleOffering.category;
            }
            
            this.age = this.selectedCattleOffering.age;
            if(this.selectedCattleOffering.breed != null){
                this.breed = this.selectedCattleOffering.breed; 
            }   
            this.getAllCatlleOfferings();
            this.$refs['cattleOfferingModal'].show()  
            
        },
        uploadFile(fileToUpload, fileType) {
            this.processing = true;               
            let formData = new FormData();
            formData.append('file', fileToUpload);
            axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
            this.processing = false;
            if(response.data) {
                this.setFileUrl(fileType, response.data);
                this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });                
            } else {
                this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
            }
            })
            .catch(error => {
            this.processing = false;
                if(error.response){
                this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
        },
        setFileUrl(fileType, fileUrl) {  
            switch(fileType) {
                case 'projectImageFile1':
                    this.projectImageUrl1 = fileUrl;
                    break;
                case 'projectImageFile2':
                    this.projectImageUrl2 = fileUrl;                    
                    break;
                case 'videoFile1':
                    this.projectVideoUrl1 = fileUrl;
                    break;
                case 'videoFile2':
                    this.projectVideoUrl2 = fileUrl;
                    break;
            }
        },
        openDocument(fileUrl) {
            window.open(fileUrl, '_blank');
        },
        viewCattleOffering(){             
            let route = this.$router.resolve({path: 'cattle-offering-details?cattleOfferCode=' + this.selectedCattleOffering.cattleOfferCode});
            this.$refs['buttons-cattle-offering-modal'].hide();
            window.open(route.href, '_blank');            
        },
        uploadVideo(fileToUpload, fileType) {
            this.processing = true;               
            let formData = new FormData();            
            formData.append('file', fileToUpload);
            axios.post('/File/UploadVideo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
            this.processing = false;
            if(response.data) {                
                this.setFileUrl(fileType, response.data);
                this.$bvToast.toast("Video subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });            
                
            } else {
                this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
            }
            })
            .catch(error => {
            this.processing = false;
                if(error.response){
                this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
        },
        openVideo(url){            
            this.url = url
            window.open(this.url, '_blank');
        }
     },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
  </style>
  